import React from 'react';
import classNames from 'classnames';

const RewardPoints = ({ pointsCost }) => {
  if (!pointsCost) {
    return null;
  }
  return (
    <span
      className={classNames(
        'flex items-center gap-4',
        'px-4 py-2 rounded-lg',
        'text-white  bg-primary',
      )}
    >
      <span className="text-2xl">{pointsCost}</span>
    </span>
  );
};

export { RewardPoints };
