import React from 'react';
import { CategoriesHeader, LoyaltyBanner } from '@fingermarkglobal/cringer.components';
import { GuestTitleComponent } from './GuestTitleComponent';
import { MemberTitleComponent } from './MemberTitleComponent';
import { OffersAndRewards } from '../offers-and-rewards';

const Header = ({
  className = '',
  showCategoriesHeader = false,
  isHeaderTitleEnabled = false,
  title = '',
  showLoyaltyBanner = false,
  computedAction = () => {},
} = {}) => (
  <div data-test="header" className={`flex flex-col bg-header ${className}`}>
    {showLoyaltyBanner && (
      <LoyaltyBanner
        computedAction={computedAction}
        MemberTitleComponent={MemberTitleComponent}
        GuestTitleComponent={GuestTitleComponent}
      />
    )}
    {showCategoriesHeader && <CategoriesHeader isIconMinimal isItemRounded={false} />}

    <OffersAndRewards />

    {title && isHeaderTitleEnabled && (
      <div
        className={`flex text-4xl px-8 py-8 pt-12 font-header items-center border-b-black border-b-2`}
      >
        <div className="flex items-center">{title}</div>
      </div>
    )}
  </div>
);

export { Header };
