import React from 'react';
import classNames from 'classnames';

import {
  ProductCardLoadableImage,
  PrimaryButton,
  useOffersAndRewardsMenu,
} from '@fingermarkglobal/cringer.components';
import { RewardPoints } from './RewardPoints';

const RewardGalleryCard = ({
  descriptive,
  image,
  title,
  description,
  price,
  energyInformation,
  serves = '',
  onClick,
  className = '',
  dataTag = 'gallery-card',
  productId,
  enableButton = false,
  buttonActionText,
  formatPrice = () => {},
  showEnergyInformationSeparated = false,
  isAvailable = true,
  disabledClass = 'opacity-30 line-through',
  fullWidth = false,
  showAddonAvailability = false,
  t = () => {},
}) => {
  const showAddonAvailabilityDisabledClass = !isAvailable && showAddonAvailability && 'opacity-30';

  const { getPromoItem } = useOffersAndRewardsMenu();
  const promoItem = getPromoItem({ productId });

  const pointsCost = promoItem?.pointsCost;
  const productImage = promoItem?.imageUrl ?? image;

  return (
    <div
      data-test={dataTag}
      className={`flex flex-col items-center justify-between shadow-lg p-2 md:p-4 pb-8 md:pb-12 w-full focus:outline-none gallery-card font-card text-center ${className}
      ${!isAvailable && !showAddonAvailability ? disabledClass : ''}`}
      onClick={!enableButton && isAvailable ? onClick : null}
      id={productId}
    >
      <div data-test="gallery-image" className="flex flex-col items-center justify-start w-full">
        <div className="relative w-full">
          <ProductCardLoadableImage
            src={productImage}
            className={classNames(
              'object-contain md:object-cover mx-auto mb-2 md:mb-4 text-center -h-card-image h-73 gallery-card-image',
              fullWidth ? 'w-full' : 'w-card-image',
              showAddonAvailabilityDisabledClass,
            )}
          />
          {!isAvailable && showAddonAvailability && (
            <p className="absolute inset-0 flex items-center justify-center text-4xl font-bold text-gray-600">
              {t('showAddonAvailabilityMessage')}
            </p>
          )}
        </div>
        <p
          data-test="gallery-title"
          className={`text-2xl md:text-3xl font-bold mb-0 md:mb-2 gallery-card__info__title ${showAddonAvailabilityDisabledClass}`}
        >
          {promoItem?.name ?? title}
        </p>
        {descriptive && description && (
          <p
            data-test="gallery-description"
            className={`mb-0 text-xl text-gray-600 md:mb-2 md:text-2xl gallery-card__info__description ${showAddonAvailabilityDisabledClass}`}
          >
            {description}
          </p>
        )}
      </div>
      {showEnergyInformationSeparated ? (
        <div>
          <p
            data-test="gallery-info-price"
            className={`text-2xl md:text-3xl gallery-card-info gallery-card__info__price ${showAddonAvailabilityDisabledClass}`}
          >
            <RewardPoints pointsCost={pointsCost} />
          </p>
          <p
            data-test="gallery-info-energyInformation"
            className={`text-2xl md:text-3xl gallery-card-info gallery-card__info__energy ${showAddonAvailabilityDisabledClass}`}
          >
            {energyInformation}
          </p>
          <p
            data-test="gallery-info-serving"
            className={`text-2xl md:text-3xl gallery-card-info gallery-card__info__serving ${showAddonAvailabilityDisabledClass}`}
          >
            {serves}
          </p>
        </div>
      ) : (
        <p
          data-test="gallery-info"
          className={`text-2xl font-bold md:text-3xl gallery-card-info gallery-card__info__price__and__energy ${showAddonAvailabilityDisabledClass}`}
        >
          <RewardPoints pointsCost={pointsCost} />
          {energyInformation ? ` | ${energyInformation}` : null}
        </p>
      )}
      {enableButton && (
        <div
          data-test="gallery-action"
          className={`gallery-action flex justify-center mt-0 md:mt-2 ${showAddonAvailabilityDisabledClass}`}
        >
          <PrimaryButton onClick={isAvailable ? onClick : null}>{buttonActionText}</PrimaryButton>
        </div>
      )}
    </div>
  );
};

export { RewardGalleryCard };
