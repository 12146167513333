import React from 'react';
import { ModalHeader, ModalFooter } from '@fingermarkglobal/cringer.components';

export const LoyaltyWelcomeModal = ({ onConfirm, loyaltyUser, t }) => {
  const { name, points } = loyaltyUser || {};

  return (
    <div className="flex flex-col justify-center p-10">
      <ModalHeader title={t('loyaltyWelcomeModalTitle', { name })} />
      <div className="flex items-center justify-center flex-initial gap-1">
        <p className="p-0 text-3xl modal-text modal-content">
          {t('loyaltyWelcomeModalPoints', { points })}
        </p>
      </div>
      <ModalFooter
        action={onConfirm}
        actionText={t('loyaltyWelcomeModalConfirm')}
        buttonsClassName={'w-full'}
      />
    </div>
  );
};
