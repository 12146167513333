import React from 'react';

import {
  BannerWarning,
  PrimaryButtonLarge,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultPrimaryBackground from '../../../assets/images/TSCPrimaryBG.jpg';

const PrimaryHome = ({
  t,
  healthMsg = true,
  primaryAction,
  secondaryAction,
  tertiaryAction,
  accessibilityEnabled = false,
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultPrimaryBackground,
          color: '#F5EBDC',
        },
      }}
    >
      <HomeCustomisation.Background />

      <div
        data-test="primary-home"
        className="relative flex flex-col items-center w-full h-full text-default"
      >
        <div className="absolute bottom-0 w-full h-full opacity-70" />

        <div className="z-10 flex flex-col w-full h-full text-center font-secondary">
          <p className="text-9xl">{t('homeTitle')}</p>

          <div
            data-test="home-upper"
            className="flex flex-wrap items-stretch justify-around mt-auto"
          >
            {!!secondaryAction && (
              <PrimaryButtonLarge
                className="w-2/5 mx-4 mb-8"
                data-test="button-secondary"
                onClick={secondaryAction}
              >
                {t('homeSecondaryAction')}
              </PrimaryButtonLarge>
            )}

            <PrimaryButtonLarge
              className="w-2/5 mx-4 mb-8"
              data-test="button-primary"
              onClick={primaryAction}
            >
              {!!secondaryAction || !!tertiaryAction
                ? t('homePrimaryAction')
                : t('homePrimaryAlternativeAction')}
            </PrimaryButtonLarge>

            {!!tertiaryAction && (
              <PrimaryButtonLarge className="w-2/5 mx-4 mb-8" onClick={tertiaryAction}>
                {t('homeTertiaryAction')}
              </PrimaryButtonLarge>
            )}

            {showTermsAndConditionsButton ||
              (healthMsg && (
                <div
                  data-test="home-lower"
                  className={`flex flex-col-reverse items-center justify-start w-full h-full mt-${
                    accessibilityEnabled ? '10' : '60'
                  }`}
                >
                  {showTermsAndConditionsButton && (
                    <BannerWarning
                      data-test="banner-terms"
                      t={t}
                      width="109"
                      className="w-4/5 mt-8 text-secondary"
                      title={t('homeTermsAndConditionsBannerWarningTitle')}
                      description={t('homeTermsAndConditionsBannerWarningDescription')}
                      additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
                      additionalDescription={t(
                        'homeTermsAndConditionsBannerWarningAdditionalDescription',
                      )}
                    />
                  )}

                  {healthMsg && (
                    <BannerWarning
                      t={t}
                      width="109"
                      data-test="banner-health"
                      className="w-4/5 mt-8 text-secondary"
                      image={WashHandsImage}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>

        <p data-test="home-footer-title" className="z-10 mb-24 text-5xl text-white">
          {t('homeFooterTitle')}
        </p>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { PrimaryHome };
