import React from 'react';

export const MemberTitleComponent = ({ t, loyaltyUser }) => {
  const { points } = loyaltyUser;
  return (
    <div className="loyalty-banner-title-wrapper">
      <p className="loyalty-banner-title-wrapper-points">{points}</p>
      <p className="loyalty-banner-title-wrapper-text">{t('loyaltyBannerPointsTitle')}</p>
    </div>
  );
};
